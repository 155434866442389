import { useContext } from 'react';

import EsportsLogo from "../../assets/images/Teams/Esports.png" 
import GSMCLogo from "../../assets/images/Teams/GSMCLogo.png"
import MVGLogo from "../../assets/images/Teams/MVGLogo.png" 
import ScarzoJLogo from '../../assets/images/ScarzoJLogo.png';

import { UserContext } from '../../context/UserContext';
import { TeamMapping, TeamShortcodes } from '../../types/teams';

interface Props {
  size?: number;
}

const teamMapping: TeamMapping = {
  default: ScarzoJLogo,
  Esports: EsportsLogo,
  GSMC: GSMCLogo,
  MVG: MVGLogo,
};

export const TeamImage = ({ size = 150 }: Props) => {
  const { user } = useContext(UserContext);
  const image =
    user.role === 'admin'
      ? teamMapping['default']
      : teamMapping[user.teams[0] as TeamShortcodes];

  return (
    <img
      height={size}
      width={size}
      style={{ borderRadius: '50%' }}
      src={image}
      alt={`${user.teams[0]} logo`}
    />
  );
};
